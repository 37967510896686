<div class="col-lg-4 col-md-6 center">
    <div class="card card-blog">
      <div class="card-header card-header-image">
        <a href="#pablo">
          <img class="img" src="assets/fwpng/snap.png">
          <div class="card-title">
            This Summer Will be Awesome
          </div>
        </a>
      <div class="colored-shadow" style="background-image: url(&quot;./assets/img/examples/card-blog1.jpg&quot;); opacity: 1;"></div></div>
     
    </div>
  
  </div>