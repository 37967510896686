
<app-header></app-header>

<app-main></app-main> 
<app-top></app-top>

<!-- <router-outlet></router-outlet> -->

<!-- <app-all></app-all> -->
<!-- <nav id="cd-vertical-nav">
    <ul>
      <li>
        <a href="#about" data-number="1">
          <span class="cd-dot"></span>
          <span class="cd-label">Headers</span>
        </a>
      </li>
      <li>
        <a href="#features" data-number="2">
          <span class="cd-dot"></span>
          <span class="cd-label">Features</span>
        </a>
      </li>
      <li>
        <a href="#blogs" data-number="3">
          <span class="cd-dot"></span>
          <span class="cd-label">Blogs</span>
        </a>
      </li>
      <li>
        <a href="#teams" data-number="4">
          <span class="cd-dot"></span>
          <span class="cd-label">Teams</span>
        </a>
      </li>
      <li>
        <a href="#projects" data-number="5">
          <span class="cd-dot"></span>
          <span class="cd-label">Projects</span>
        </a>
      </li>
      <li>
        <a href="#pricing" data-number="6">
          <span class="cd-dot"></span>
          <span class="cd-label">Pricing</span>
        </a>
      </li>
      <li>
        <a href="#testimonials" data-number="7">
          <span class="cd-dot"></span>
          <span class="cd-label">Testimonials</span>
        </a>
      </li>
      <li>
        <a href="#contactus" data-number="8">
          <span class="cd-dot"></span>
          <span class="cd-label">Contact Us</span>
        </a>
      </li>
    </ul>
  </nav> -->