<div class="contactus-1 section-image" style="background-image: url('./assets/back.jpg')">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <h2 class="title">ابقى على تواصل</h2>
        <h5 class="description">هل تحتاج إلى مزيد من المعلومات؟تواصل معنا.</h5>
        <a href="https://api.whatsapp.com/send?phone=966551007072"><img src='assets/img/whatsapp.png'  alt="" class="imag-slider" style="    width: 37px;cursor: pointer;" /></a>

    
        <div class="info info-horizontal">
          
         
        </div>
        <div class="info info-horizontal">
          <div class="icon icon-primary">
            <i class="material-icons">phone</i>
          </div>
          <div class="description" >
            <h4 class="info-title"> اتصل بنا</h4>
            <p style="color: white;"> الرياض
              <br> 0551007072
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-5 ml-auto">
        <div class="card card-contact">
          <form id="contact-form" method="post">
            <div class="card-header card-header-raised card-header-primary text-center" style="background: #216cd1;">
              <h4 class="card-title">تواصل معنا</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group label-floating is-empty bmd-form-group">
                    <input type="number" name="name" class="form-control" placeholder='رقم الجوال'>
                    <span class="material-input"></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group label-floating is-empty bmd-form-group">
                    <input type="text" name="name" class="form-control"  placeholder='الاسم كامل'>
                    <span class="material-input"></span>
                  </div>
                </div>
                
              </div>
              <div class="form-group label-floating is-empty bmd-form-group">
                <!-- <label class="bmd-label-floating">Email address</label> -->
                <input type="email" name="email" class="form-control"   placeholder='البريد الاكتروني'>
                <span class="material-input"></span>
              </div>
              <div class="form-group label-floating is-empty bmd-form-group">
                <textarea name="message" class="form-control" id="exampleMessage1" rows="6" placeholder='هنا رسالتك لنا '></textarea>
                <span class="material-input"></span>
              </div>
            </div>
            <div class="card-footer justify-content-between">
            
              <button  class="btn btn-primary pull-right"  style="background: #216cd1;"> إرسال</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>