import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { AllComponent } from './all/all.component';
import { SliderComponent } from './slider/slider.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TopComponent } from './top/top.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { HttpModule } from '@angular/http';
import { AnnouncementComponent } from './announcement/announcement.component';
import { MainpageComponent } from './mainpage/mainpage.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    MainComponent,
    AllComponent,
    SliderComponent,
    ContactUsComponent,
    TopComponent,
    AnnouncementComponent,
    MainpageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
