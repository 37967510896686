import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  feedback:any;
  email:'s.sultan7775@gmail.com';
  femail='s.sultan7775@gmail.com';
  name:'Suhaib';
  message:'sadsdasdas';
  fileObj={
    filename:'',
    content: '',
    encoding: 'base64'}
  constructor( 
    private httpClient:HttpClient,
    
    private http:Http) {
}

  ngOnInit(): void {
   
  }

  pop(){
    Swal.fire(
      'لقد تم ارسال الايميل!',
      '',
      'success'
    )
  }
  sendEmail() {
    console.log("feedback",this.feedback)
    let url = `https://api.sendgrid.com/v3/mail/send`
  
    let headers = new Headers(
      {'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    'Authorization':'Bearer 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'
   }
   );
    let options: RequestOptions = new RequestOptions({ headers: headers });
   
  
    let body={"personalizations": [{"to": [{"email": "s.sultan7775@gmail.com"}]}],"from": {"email": "laban.webkey@gmail.com"},"subject": "مرحبا بك في مفتاح لبن!","content": [{"type": "text/plain", "value": "مرحبا بك في مفتاح لبن!!"}]}
    console.log("body",body);
    return this.http.post(url, body, options)
                    .subscribe(res => {
                      console.log(res)
                    },err=>{
  
                    })
  }
}
