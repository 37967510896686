<div class="col-md-10 mr-auto ml-auto text-center">
    <h2 class="title aos-init aos-animate" data-aos="fade-up">عملاءنا


    </h2>
   
  </div>
<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let sl of slider01">
      <img src={{sl.img}}  alt="" class="imag-slider" />
    </div>
  
  </div>
</div>


<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let sl of slider1">
      <img src={{sl.img}}  alt="" class="imag-slider" />
    </div>
  
  </div>
</div>
<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let sl of slider02">
      <img src={{sl.img}} height="200" width="250" alt="" class="imag-slider" />
    </div>
  
  </div>
</div>

<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let sl of slifer04">
      <img src={{sl.img}} height="200" width="250" alt="" class="imag-slider" />
    </div>
  
  </div>
</div>
<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let sl of slifer05">
      <img src={{sl.img}} height="200" width="250" alt="" class="imag-slider" />
    </div>
  
  </div>
</div>


