<div class="main main-raised">
    <div class="section section-basic">
      <div class="container">
  
        <div class="col-md-10 mr-auto ml-auto text-center" id="about" data-aos="fade-up">
          <h2 class="title aos-init aos-animate" data-aos="fade-up">من نحن
  
  
          </h2>
          <h3 class="description aos-init aos-animate" data-aos="fade-up" style="text-align: justify;    font-size: 21px;">
            شركة سعودية في مجال الدعاية والاعلان نعمل وفق أسس ومعايير عالية جمعنا حب العمل والشغف والإبداع لنقدم مثال
            يحتذى به للجودة والاحترافية
  
  
          </h3>
        </div>
        <div class="row web">
          <div class="col-md-3 center" data-aos="fade-up"> 
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">video_camera_front</i>
              </div>
              <h4 class="info-title">الإنتاج المرئى
  
              </h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">manage_accounts</i>
              </div>
              <h4 class="info-title">إدارة الحسابات على
                مواقع التواصل الإجتماعي</h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">shop</i>
              </div>
              <h4 class="info-title">التسويق الرقمي
                والحملات التسويقية
                
                </h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">history_edu</i>
              </div>
              <h4 class="info-title">رسم الخطة التسويقية
                والاستراتيجيات</h4>
            </div>
          </div>
        </div>
        <div class="row web">
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">groups</i>
              </div>
              <h4 class="info-title">المؤثرين
  
              </h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">devices</i>
              </div>
              <h4 class="info-title">التصميم الإبداعي
  
              </h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">description</i>
              </div>
              <h4 class="info-title">كتابة المحتوى
  
  
                
                </h4>
            </div>
          </div>
        
        </div>
        <div class="row" >
          <div class="col-6 center" data-aos="fade-up" id="mobi"> 
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">video_camera_front</i>
              </div>
              <h4 class="info-title">الإنتاج المرئى
  
              </h4>
            </div>
          </div>
          <div class="col-6 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">manage_accounts</i>
              </div>
              <h4 class="info-title">إدارة الحسابات على
                مواقع التواصل الإجتماعي</h4>
            </div>
          </div>
          <div class="col-6 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">shop</i>
              </div>
              <h4 class="info-title">التسويق الرقمي
                والحملات التسويقية
                
                </h4>
            </div>
          </div>
          <div class="col-6 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">history_edu</i>
              </div>
              <h4 class="info-title">رسم الخطة التسويقية
                والاستراتيجيات</h4>
            </div>
          </div>
        </div>
        <div class="row " >
          <div class="col-6 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">groups</i>
              </div>
              <h4 class="info-title">المؤثرين
  
              </h4>
            </div>
          </div>
          <div class="col-6 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">devices</i>
              </div>
              <h4 class="info-title">التصميم الإبداعي
  
              </h4>
            </div>
          </div>
          <div class="col-md-3 center" data-aos="fade-up" id="mobi">
            <div class="info">
              <div class="icon icon-laban">
                <i class="material-icons">description</i>
              </div>
              <h4 class="info-title">كتابة المحتوى
  
  
                
                </h4>
            </div>
          </div>
        
        </div>
  
  
       
        <!--                 end sliders -->
      </div>
    </div>
    <app-slider></app-slider>
    <app-contact-us></app-contact-us>
    <footer class="footer footer-white">
      <div class="container">
   
    
        <ul class="pull-center">
          <li>
            <a href="#about" data-number="1" class="nav-link">
              من نحن
            </a>
          </li>
          <li>
            <a href="#pablo" class="nav-link">
              خدماتنا
            </a>
          </li>
          <li>
            <a href="#pablo" class="nav-link">
              عملائنا
            </a>
          </li>
        </ul>
        <br>
        <ul class="social-buttons pull-center">
          <li>
            <a href="https://twitter.com/CreativeTim" target="_blank" class="btn btn-just-icon btn-link btn-twitter">
              <i class="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/CreativeTim" target="_blank" class="btn btn-just-icon btn-link btn-dribbble">
              <i class="fa fa-dribbble"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/CreativeTimOfficial" target="_blank" class="btn btn-just-icon btn-link btn-google">
              <i class="fa fa-google-plus"></i>
            </a>
          </li>
  
          <li>
            <a href="https://www.instagram.com/CreativeTimOfficial" target="_blank" class="btn btn-just-icon btn-link btn-google">
              <i class="fa fa-snapchat-square" aria-hidden="true"></i>
            </a>
          </li>
  
          <li>
            <a href="https://www.instagram.com/CreativeTimOfficial" target="_blank" class="btn btn-just-icon btn-link btn-google">
              <i _ngcontent-wnd-c18="" class="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
    </div>