<nav class="navbar navbar-transparent navbar-absolute navbar-expand-lg" dir="rtl">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-translate">
            <!-- <img class="logo-header" src="assets/fwpng/logo-white.png"> -->
      <button type="button" class="ml-auto navbar-toggler" data-toggle="collapse" data-target="#navigation-example2">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="navigation-example2 cd-vertical-nav" >
      <ul class="navbar-nav navbar-center ml-auto">
        <li class="nav-item">
          <a href="#about" data-number="1" class="nav-link">
            من نحن
          </a>
        </li>
        <li class="nav-item">
          <a href="#pablo" class="nav-link">
            خدماتنا
          </a>
        </li>
        <li class="nav-item">
          <a href="#pablo" class="nav-link">
            عملاءنا
          </a>
        </li>
        <li class="nav-item">
          <a href="#pablo" class="nav-link">
           تواصل معنا
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" style="margin-left: 0 !important;">
        <li class="nav-item">
          <a href="https://twitter.com/laban_key" class="nav-link">
            <i class="fa fa-twitter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.snapchat.com/add/laban.key" class="nav-link">
            <i class="fa fa-snapchat-square" aria-hidden="true"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://instagram.com/laban.key?utm_medium=copy_link" class="nav-link">
            <i class="fa fa-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="page-header header-filter clear-filter" data-parallax="true" >
  
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <div class="brand">
            <img class="logo" src="assets/fwpng/logo-white.png">
            <!-- <h1>  مفتاح 
              <span class="pro-badge">
                لبن
              </span>
            </h1>
            <h3 class="title">All Components </h3> -->
          </div>
        </div>
      </div>
    </div>
  </div>