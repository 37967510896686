import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
slider=[


  {
  img:'assets/png_1/-S8DeRsY_400x400.png'
},
{
  img:'assets/png_1/1f3c2aec075e43d2ea341242a61c1fe4.png'
}, {
  img:'assets/png_1/4.png'
}, {
  img:'assets/png_1/6hHU0aaT_400x400.png'
}, {
  img:'assets/png_1/6p-W9P76_400x400.png'
}, {
  img:'assets/png_1/7kAdvHgp.png'
}, {
  img:'assets/png_1/9f191b3923d46dcb400d7637cf1a23ec-small.png'
}, {
  img:'assets/png_1/9Jr74Ya2EFUqNZy5ZHtyBsYjGM9IcHpE85YNxzzz.png'
},
{
  img:'assets/png_1/81e2fe06-cfce-4816-b061-e6bab47ae0d0-200x.png'
},
{
  img:'assets/png_1/232cfb51d0f31.png'
}, {
  img:'assets/png_1/402ecd82a2c7405b5d9c235d8728a917-small.png'
}, {
  img:'assets/png_1/1024x1024bb.png'
}, {
  img:'assets/png_1/88493.png'
},
 {
  img:'assets/png_1/117997.png'
},  {
  img:'assets/png_1/494210-36789-20200210052851-9605906.png'
},  {
  img:'assets/png_1/alrbyaa-mol-lltjar-1600212148-239.png'
},  {
  img:'assets/png_1/c55cbb_4955d4c90f8f41a3986730b83fd8ec91_mv2.png'
},  {
  img:'assets/png_1/CdVbbg9WIAEzdHl.png'
},  {
  img:'assets/png_1/D_JSmQ0XoAYA1RK.png'
},  {
  img:'assets/png_1/dgj3h2qJ.png'
},  {
  img:'assets/png_1/dIdfW88e.png'
},  {
  img:'assets/png_1/DIPL_TXXkAA_wD0.png'
},  {
  img:'assets/png_1/e-4W4Blb.png'
},  {
  img:'assets/png_1/e41aTEy5.png'
},  {
  img:'assets/png_1/EKyFr0LXsAAsQdf.png'
},  {
  img:'assets/png_1/euyUCfcW_400x400.png'
},  {
  img:'assets/png_1/G7n1FVCK.png'
},  {
  img:'assets/png_1/GKlb_FVg.png'
},  {
  img:'assets/png_1/gV629Cbn_400x400.png'
},  {
  img:'assets/png_1/iltmdd_g.png'
},  {
  img:'assets/png_1/images.png'
},  {
  img:'assets/png_1/Jejdend4.png'
},  {
  img:'assets/png_1/L-qn_Ytw.png'
},  {
  img:'assets/png_1/lBgDtCxI_400x400.png'
}, 
{
  img:'assets/png_1/lbwsL3V8FjYxOzzmi1pR84AuzVQ4ExQTa0SIixMw.png'
}, {
  img:'assets/png_1/Logo (1).png'
}, {
  img:'assets/png_1/logo-sh.png'
}, {
  img:'assets/png_1/logo.png'
}, {
  img:'assets/png_1/MC3V9cHA.png'
}, {
  img:'assets/png_1/meswak-dark.png'
}, {
  img:'assets/png_1/NcoZYSWK_400x400.png'
}, {
  img:'assets/png_1/nsd8iEBk.png'
}, {
  img:'assets/png_1/OtbZ93B1.png'
}, {
  img:'assets/png_1/p_VRw33p.png'
}, {
  img:'assets/png_1/pHt5hI1Y_400x400.png'
}, 
{
  img:'assets/png_1/primary-care_20190327172731222.png'
}, {
  img:'assets/png_1/Pv2qEFOc_400x400.png'
}, 
{
  img:'assets/png_1/QJE5yd-I.png'
}, 
{
  img:'assets/png_1/Rrms6rjq.png'
}
]



slider01=[

  {
    img:'assets/albom1/00.png'
  },

  {
    img:'assets/albom1/01.jpg'
  },
  {
    img:'assets/albom1/1.png'
  },
  {
    img:'assets/albom1/2.png'
  },
  {
    img:'assets/albom1/3.png'
  },
  {
    img:'assets/albom1/04.jpg'
  },
  {
    img:'assets/albom1/4.png'
  },
  {
    img:'assets/albom1/5.png'
  },
  {
    img:'assets/albom1/6.png'
  },
  {
    img:'assets/albom1/06.png'
  },
  {
    img:'assets/albom1/7.png'
  },
  {
    img:'assets/albom1/07.png'
  },
  {
    img:'assets/albom1/8.png'
  },
  {
    img:'assets/albom1/08.png'
  },
  {
    img:'assets/albom1/9.png'
  },
  {
    img:'assets/albom1/09.png'
  }
  
  

]
slider1=[
  {
    img:'assets/albom2/10.png'
  },
  {
    img:'assets/albom2/11.png'
  },
  {
    img:'assets/albom2/12.png'
  },
  // {
  //   img:'assets/albom2/013.png'
  // },
  {
    img:'assets/albom2/13.png'
  },
  {
    img:'assets/albom2/14.png'
  },
  {
    img:'assets/albom2/15.png'
  },
  {
    img:'assets/albom2/16.png'
  },
  {
    img:'assets/albom2/17.png'
  },
  {
    img:'assets/albom2/18.png'
  },
  {
    img:'assets/albom2/19.png'
  },
  {
    img:'assets/albom2/20.png'
  },
  {
    img:'assets/albom2/21.png'
  },
  {
    img:'assets/albom2/22.png'
  },
  {
    img:'assets/albom2/23.png'
  },
  {
    img:'assets/albom2/24.png'
  },
  {
    img:'assets/albom5/73.png'
  }, 
]
slider02=[
  {
    img:'assets/albom3/25.png'
  },
  {
    img:'assets/albom3/26.png'
  },
  {
    img:'assets/albom3/27.png'
  },
  {
    img:'assets/albom3/28.png'
  },
  {
    img:'assets/albom3/29.png'
  },
  {
    img:'assets/albom3/30.png'
  },
  {
    img:'assets/albom3/31.png'
  },
  {
    img:'assets/albom3/32.png'
  },
  {
    img:'assets/albom3/33.png'
  },
  {
    img:'assets/albom3/34.png'
  },
  {
    img:'assets/albom3/35.png'
  },
  {
    img:'assets/albom3/36.png'
  },
  {
    img:'assets/albom3/37.png'
  },
  {
    img:'assets/albom3/38.png'
  },
  {
    img:'assets/albom3/39.png'
  },
   {
    img:'assets/albom3/40.png'
  }
]
slifer04=[
  {
    img:'assets/albom4/41.png'
  },
  {
    img:'assets/albom4/42.png'
  },
  {
    img:'assets/albom4/43.png'
  },
  {
    img:'assets/albom4/44.png'
  }, {
    img:'assets/albom4/45.png'
  }, {
    img:'assets/albom4/46.png'
  },
  {
    img:'assets/albom4/47.png'
  }, {
    img:'assets/albom4/48.png'
  }, {
    img:'assets/albom4/49.png'
  }, {
    img:'assets/albom4/50.png'
  }, {
    img:'assets/albom4/51.png'
  }, {
    img:'assets/albom4/52.png'
  }, {
    img:'assets/albom4/53.png'
  },
  {
    img:'assets/albom4/54.png'
  }, {
    img:'assets/albom4/55.png'
  },
  {
    img:'assets/albom4/56.png'
  },
]
slifer05=[
  {
    img:'assets/albom5/57.png'
  },
  {
    img:'assets/albom5/58.png'
  },
  {
    img:'assets/albom5/59.png'
  }, {
    img:'assets/albom5/60.png'
  }, {
    img:'assets/albom5/61.png'
  }, {
    img:'assets/albom5/62.png'
  }, {
    img:'assets/albom5/63.png'
  }, {
    img:'assets/albom5/64.png'
  }, {
    img:'assets/albom5/65.png'
  }, {
    img:'assets/albom5/66.png'
  }, {
    img:'assets/albom5/67.png'
  }, {
    img:'assets/albom5/68.png'
  },
  {
    img:'assets/albom5/69.png'
  }, {
    img:'assets/albom5/70.png'
  }, {
    img:'assets/albom5/71.png'
  }, {
    img:'assets/albom5/72.png'
  }
  // {
  //   img:'assets/albom5/74.png'
  // }, 


]
  constructor() { }

  ngOnInit(): void {
  }

}
